import { computed, ref } from "vue";
import { useStore } from "vuex";

const useHelpQuestions = () => {
  const store = useStore();

  const isLoadingQuestions = computed(
    () => store.getters["students/helpIsLoadingQuestions"]
  );
  const categoryQuestions = computed(
    () => store.getters["students/helpCategoryQuestions"]
  );

  const searchResults = computed(
    () => store.getters["students/helpSearchResults"]
  );
  const isSearching = computed(
    () => store.getters["students/helpIsHelpSearching"]
  );

  const selectedCategory = (category, isSelected) => {
    store.commit("students/helpIsSearching", false);
    if (isSelected) {
      store.commit("students/helpResetSearching");
    }

    const hasCategories = categoryQuestions.value.length > 0;
    if (hasCategories) {
      let currentCategory =
        categoryQuestions.value.filter((cat) => cat.category === category)[0] ??
        [];
      if (typeof currentCategory === "object") {
        let { icon, categoryID, category, ...currentCategory_ } =
          currentCategory;

        store.commit("students/helpUpdateQuestionIds", []);
        store.commit("students/helpSelectedCategory", {
          ...currentCategory_,
          currentCategoriesIds: [currentCategory.categoryID],
        });
        if (currentCategory_.questions.length > 0) {
          const firstQuestionId = currentCategory_.questions[0].id;
          selectedQuestion(firstQuestionId);
        }
      }
      return;
    }

    /*      */
  };

  const selectedQuestion = (idQuestion) => {
    store.commit("students/helpCurrentQuestionIds", idQuestion);
  };

  const currentQuestionIds = computed(
    () => store.getters["students/helpCurrentQuestionIds"]
  );

  const textSearching = ref("");

  const searching = () => {
    store.commit("students/helpIsSearching", true);
    store.commit("students/helpUpdateQuestionIds", []);
    store.commit("students/helpSearching", textSearching.value);
    textSearching.value = "";
  };

  const clearText = () => {
    textSearching.value = "";
  };

  const toggleQuestionAccordeon = (id) => {
    let currentQuestionIds_ = [];

    if (currentQuestionIds.value.includes(id)) {
      currentQuestionIds_ = currentQuestionIds.value.filter((c) => c !== id);
      store.commit("students/helpUpdateQuestionIds", currentQuestionIds_);
    } else {
      store.commit("students/helpCurrentQuestionIds", id);
    }
  };

  return {
    isLoadingQuestions,
    categoryQuestions,
    searchResults,
    isSearching,
    selectedCategory,
    selectedQuestion,
    currentQuestionIds,
    textSearching,
    searching,
    clearText,
    toggleQuestionAccordeon,
  };
};

export default useHelpQuestions;
