<template>
  <div class="bg-white bl-shadow border-r-10 mb-3">
    <div
      class="
        bl-shadow
        py-3
        px-4
        border-r-10
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h2 class="fs-5 fw-bold m-0">
        {{ question }}
      </h2>
      <i
        v-if="currentQuestionIds.includes(id)"
        @click="toggleQuestionAccordeon(id)"
        class="pi pi-minus-circle fs-3 rounded-circle text-primary-1 pointer"
      ></i>
      <i
        v-else
        class="
          pi pi-plus-circle
          fs-3
          rounded-circle
          bg-primary-1
          rounded-circle
          text-white
          pointer
        "
        @click="toggleQuestionAccordeon(id)"
      ></i>
    </div>
    <div class="px-4 py-3 fs-6" v-if="currentQuestionIds.includes(id)">
      <p v-html="answer">
      </p>
    </div>
  </div>
</template>

<script>
import useHelpQuestions from "@/modules/students/composables/Help/useHelpQuestions";

export default {
  name: "QuestionAndAnswerComponent",
  props: {
    id: {
      type: Number,
    },
    question: {
      type: String,
    },
    answer: {
      type: String,
    },
  },

  setup() {
    const { currentQuestionIds, toggleQuestionAccordeon } = useHelpQuestions();
    return { currentQuestionIds, toggleQuestionAccordeon };
  },
};
</script>

