import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import  useMenuBar from '@/shared/composables/useMenuBar'
const useHelp = () => {
  const store = useStore();
  const { toggleMenuHamburger  } = useMenuBar()
  onBeforeMount(async () => {
    await store.dispatch("students/getHelpQuestions");
  });
  const openMiniTour = () => {
    toggleMenuHamburger()

    setTimeout(() => {
      store.commit('students/startTour', { isOpen: true })
    },700)
  }


  return {
    openMiniTour
  };
};
export default useHelp;
