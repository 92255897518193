import { Store, useStore } from "vuex";
import { computed, onBeforeMount, ref } from "vue";
import useSharedUI from "@/shared/composables/useSharedUI";
import { useRouter } from "vue-router";

const useMenuBar = () => {
  const { toggleNotificationList, getNotificationList } = useSharedUI();
  const store = useStore();
  const router = useRouter();
  const isCurrentStudentProfile = ref(false);
  const isShowHamburger = ref(false);
  onBeforeMount(() => {
    isCurrentStudentProfile.value =
      router.currentRoute.value.name === "student-profile";
  });

  const userAuthenticated = computed(
    () => store.getters["shared/getUserAuthentication"]
  );

  const isShowUserInfo = computed(
    () => store.getters["shared/getUserInfoModal"]
  );

  const toggleUserInfo = () => {
    if (userAuthenticated.value.role !== "student") {
      const isOpen = !isShowUserInfo.value.isOpen;
      store.commit("shared/uiUserInfoModal", {
        isOpen,
      });
    } else if (userAuthenticated.value.role === "student") {
      router.push({
        name: "student-profile",
      });
    }
    store.commit("shared/menuHamburger", { isOpen : false }); 
  };

  const toggleMenuHamburger = () => {
    const { isOpen } = store.getters["shared/menuHamburger"];
    store.commit("shared/menuHamburger", { isOpen : !isOpen }); 
  }

  const menuHamburger = computed(() => {
    return store.getters["shared/menuHamburger"];
  })

  return {
    menuHamburger,
    toggleMenuHamburger,
    isShowHamburger,
    toggleNotificationList,
    getNotificationList,
    userAuthenticated,
    isShowUserInfo,
    toggleUserInfo,
  };
};

export default useMenuBar;
