<template>
  <div
    class="
      bg-primary-5
      border-r-10
      p-3
      pt-4
      col-one
      d-flex
      justify-content-center
    "
  >
    <div class="d-flex gap-3 flex-column align-items-center w-100">
      <h2 class="text-primary-0 fs-1 fw-bold">Any other question?</h2>

      <skeleton
        class="input-w-search skeleton-dark"
        v-if="isLoadingQuestions"
        width="100%"
        height="3rem"
        borderRadius="10px"
      />
      <div
        class="input-w-search"
        :class="textSearching.length > 0 ? 'input-icon-clear' : ''"
        v-else
      >
        <span class="p-input-icon-right">
          <i
            class="
              pi
              bg-primary-1
              text-white
              py-2
              px-3
              fw-bold
              px-2
              bl-shadow
              pointer
              pi-search
            "
            @click="searching"
          />
          <i
            class="pi pi-times fw-bold px-2 pointer"
            @click="clearText"
            v-if="textSearching.length > 0"
          ></i>
          <input-text
            type="text"
            placeholder="Start typing your search..."
            class="border-0 border-r-10 bl-shadow icon-search"
            v-model="textSearching"
          />
        </span>
      </div>

      <div
        class="
          mt-4
          mb-3
          d-flex
          gap-1
          flex-wrap flex-row
          justify-content-sm-between
          justify-content-center

          help-questions-category
        "
      >
        <template v-if="!isLoadingQuestions">
          <question-category-component
            v-for="(cat, index) in categoryQuestions"
            :key="index"
            :category="cat.category"
            :icon="cat.icon"
            :isSelected="
              searchResults.currentCategoriesIds.includes(cat.categoryID)
            "
          />
        </template>
        <question-category-component
          v-else
          v-for="(skeleton, index) in [1, 2, 3, 4, 5, 6]"
          :key="index"
          :isLoading="isLoadingQuestions"
        />
      </div>

      <questions-results-component v-if="searchResults.questions.length > 0" />
      <questions-results-empty-component
        v-if="isSearching && searchResults.questions.length <= 0"
      />
    </div>
  </div>
</template>

<script>
import QuestionCategoryComponent from "./QuestionCategoryComponent";
import QuestionsResultsComponent from "./QuestionsResultsComponent";
import QuestionsResultsEmptyComponent from "./QuestionsResultsEmptyComponent";

import useHelpQuestions from "@/modules/students/composables/Help/useHelpQuestions";

export default {
  name: "QuestionsComponent",
  components: {
    QuestionCategoryComponent,
    QuestionsResultsComponent,
    QuestionsResultsEmptyComponent,
  },
  setup() {
    return { ...useHelpQuestions() };
  },
};
</script>

<style lang="scss" scoped>
.help-questions-category,
.help-questions-results,
.help-questions-empty {
  max-width: 1000px;
  width: 100%;
}
</style>