<template>
  <div class="bg-primary-2 border-r-10 p-3 col-one text-white text-center">
    <p>
      If you have any other questions, you can write to us at:
      <span class="fw-bold">support@brightlingua.com</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactComponent",
};
</script>