<template>
  <div
    class="
      bg-secondary-3
      p-4
      w-100
      d-block
      mt-4
      border-r-10
      mb-2
      overflow-visible
      help-questions-results
    "
  >
    <question-and-answer-component
      v-for="(question, index) in searchResults.questions"
      :key="index"
      :question="question.question"
      :answer="question.answer"
      :id="question.id"
    />
  </div>
</template>

<script>
import QuestionAndAnswerComponent from "@/modules/students/components/Help/QuestionAndAnswerComponent";
import useHelpQuestions from "@/modules/students/composables/Help/useHelpQuestions";

export default {
  name: "QuestionsResultsComponent",
  components: {
    QuestionAndAnswerComponent,
  },
  setup() {
    const { searchResults } = useHelpQuestions();
    return { searchResults };
  },
};
</script>



