<template>
  <skeleton
    class="skeleton-dark card-help-loading"
    width="100%"
    height="10rem"
    borderRadius="10px"
    v-if="isLoading"
  />
  <div
    v-else
    class="
      card-help
      px-3
      py-4
      d-flex
      flex-column
      gap-3
      align-items-center
      justify-content-center
      bl-shadow
      border-r-10
      pointer
    "
    :class="{
      categorySelected: isSelected && !isSearching,
      categorySelectedSearching: isSelected && isSearching,
    }"
    @click="selectedCategory(category, isSelected)"
  >
    <img :src="icon" alt="" />
    <div class="d-flex align-items-center h-100">
      <p class="fs-5 text-center fw-bold" v-html="category">
      </p>
    </div>
  </div>
</template>

<script>
import useHelpQuestions from "@/modules/students/composables/Help/useHelpQuestions";

export default {
  name: "QuestionCategoryComponent",
  props: {
    icon: {
      type: String,
    },
    category: {
      type: String,
    },
    isSelected: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    const { selectedCategory, isSearching } = useHelpQuestions();
    return { selectedCategory, isSearching };
  },
};
</script>

<style lang="scss" scoped>
.card-help-loading {
  max-width: 160px;
}
.card-help {
  background-image: url("/assets/modules/students/Help/bg-card.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  max-width: 134px;
  overflow: visible;
  width: 100%;
  position: relative;
  & img {
    height: 50px;
    max-width: 50px;
    object-fit: contain;
  }
  &.categorySelected,
  &:hover,
  &.categorySelectedSearching {
    background-image: url("/assets/modules/students/Help/bg-card-selected.png");
  }
  & p::first-letter {
    text-transform: uppercase;
  }
  &.categorySelected::before {
    content: "";
    border-style: solid;
    border-width: 40px 40px 59px 16px;
    border-color: transparent #fff0e7 transparent transparent;
    position: absolute;
    left: 50px;
    top: 150px;
    transform: rotate(90deg);
  }
}
</style>