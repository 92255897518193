<template>
  <div class="help-grid">
    <banner-component />
    <learn-more-cards-component />

    <questions-component />
    <contact-component />
  </div>
</template>


<script>
import BannerComponent from "@/modules/students/components/Help/BannerComponent";
import LearnMoreCardsComponent from "@/modules/students/components/Help/LearnMoreCardsComponent";
import QuestionsComponent from "@/modules/students/components/Help/QuestionsComponent";
import ContactComponent from "@/modules/students/components/Help/ContactComponent";
import useHelp from "@/modules/students/composables/Help/useHelp";

export default {
  name: "HelpView",
  components: {
    BannerComponent,
    LearnMoreCardsComponent,
    ContactComponent,
    QuestionsComponent,
  },
  setup() {
    return {
      ...useHelp(),
    };
  },
};
</script>

<style lang="scss" scoped>
.help-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  column-gap: 2rem;
  & .col-one {
    grid-column: 1 / 3;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: 0;
    & .col-one {
      grid-column: 1 / -1;
    }
  }
}
.help-banner-grid {
  display: grid;
  grid-template-columns: 1fr 12rem;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}
</style>