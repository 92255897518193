<template>
  <div
    class="
      bg-secondary-3
      p-4
      w-100
      d-block
      mt-4
      border-r-10
      mb-2
      help-questions-empty
    "
  >
    <h2 class="fs-4 m-0 fw-bold text-secondary-2">Not Found</h2>
  </div>
</template>

<script>
export default {
  name: "QuestionsResultsEmptyComponent",
};
</script>