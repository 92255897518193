<template>
  <div class="bg-white border-r-10 bl-shadow p-4 text-center">
    <a href="https://support.zoom.us/hc/en-us" target="_blank" class="fs-4 text-secondary-1 text-hover-secondary-1 pointer">
      Learn more about Zoom
    </a>
  </div>
  <div class="bg-white border-r-10 bl-shadow p-4 text-center">
    <a  href="https://support.google.com/calendar/community?hl=en" target="_blank" class="fs-4 text-secondary-1 text-hover-secondary-1 pointer">
      Learn more about Google Calendar
    </a>
  </div>
</template>

<script>
export default {
  name: "LearnMoreCardsComponent",
};
</script>