<template>
  <div class="bg-primary-5 border-r-10 p-3 help-banner-grid col-one">
    <div>
      <h1 data-intro="This is your message" class="text-primary-0 fw-bold">
        Do you need help?
      </h1>
      <p class="py-2">No problem, view the onboarding again anytime!</p>
    </div>
    <button
      @click="openMiniTour"
      class="
        btn
        text-white
        bg-secondary-1 bg-hover-secondary-1
        bl-shadow
        p-2
        px-5
        mb-2
        w-100
      "
    >
      Mini tour
    </button>
  </div>
</template>
<script>
import useHelp from "@/modules/students/composables/Help/useHelp";
export default {
  name: "BannerComponent",
  components: {},
  setup() {
    const { openMiniTour } = useHelp();
    return {
      openMiniTour
    };
  },
};
</script>
